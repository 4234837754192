<template>
  <div class="d-flex justify-content-center container mt-5">
    <b-card>
      <h4>
        Validation de devis N° :
        <b class="alert alert-primary">{{ invoice.devis_num }}</b>
      </h4>
      <hr />
      <b-row>
        <b-col md="4">
          <img
            src="../../../../public/validate.svg"
            class="img-fluid"
            style="max-height: 350px"
            alt=""
          />
        </b-col>
        <b-col md="8">
          <fieldset>
            <legend>
              Bonjour <b> {{ customerName(invoice) }} </b>
            </legend>
            Voici ci-dessous le lien de téléchargement de votre devis. Veuillez
            s'il-vous-plait en prendre connaissance.

            <br />
            <br />
            <b-btn
              :disabled="loading"
              v-if="isCodeSent == false"
              @click="sendCode"
            >
              <b-spinner small v-if="loading" /> Vérifier mon identité</b-btn
            >
            <div v-if="isCodeSent == true && isCorrectCode == false">
              <span>
                Un code de vérification a été envoyé à l'adresse mail
                <strong> {{ maskEmail(invoice) }}</strong
                >, veuillez renseigner ce code.
              </span>
              <br />
              <br />
              <b-form-group label="Code de vérification">
                <b-form-input
                  style="width: 10rem"
                  type="number"
                  v-model="data.code"
                  placeholder="* * * *"
                ></b-form-input>
                <small v-if="codeRequired" class="text-danger"
                  >Veuillez renseigner le code</small
                >
              </b-form-group>
              <b-btn @click="checkCode" :disabled="loading">
                <b-spinner small v-if="loading" /> Vérifier</b-btn
              >
            </div>

            <div class="mt-2" v-if="catchError">
              <p class="alert p-1 alert-danger">
                {{ message }}
              </p>
            </div>
            <div v-if="isCorrectCode">
              <b-link
                :href="invoice.filename_devis"
                target="_blank"
                class="mt-4"
              >
                <feather-icon icon="FileIcon" /> Téléchager le devis
              </b-link>
              <div
                class="mt-5 p-5"
                v-if="isValidate == false && invoice.is_validate == 0"
              >
                <b-button
                  class="mr-1"
                  variant="outline-primary"
                  @click="resetThisDevis(invoice)"
                  >Devis erroné</b-button
                >
                <b-button
                  :disabled="isValidating"
                  @click="validateThisDevis(invoice)"
                >
                  <b-spinner small v-if="isValidating" /> Valider le
                  devis</b-button
                >
              </div>
              <div
                class="alert alert-success mt-5 p-1"
                v-if="
                  isValidate == true ||
                  (invoice.is_validate == 1 && isReset == false)
                "
              >
                <p>Devis validé avec succès</p>
              </div>
              <div
                class="alert alert-danger mt-5 p-1"
                v-if="isReset == true || invoice.is_validate == 2"
              >
                <p>Devis rejeté avec succès</p>
              </div>
            </div>
          </fieldset>
        </b-col>
      </b-row>
      <b-card-footer class="text-right">
        <p>eKoworking - Espace de travail</p>
      </b-card-footer>
    </b-card>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  setup() {
    return {
      validateData: {
        email: '',
        devis_num: "",
      },
      isValidating: false,
      loading: false,
      message: "",
      isCodeSent: false,
      isCorrectCode: false,
      isValidate: false,
      isReset: false,
      code: null,
      catchError: false,
      data: {
        code: null,
        customer_id: null,
      },
      codeRequired: false,
    };
  },
  computed: {
    ...mapState("tasks", ["invoice"]),
  },
  mounted() {
    this.getDevisCustomer(this.$route.params.num);
  },
  methods: {
    customerName(item) {
      return item.customer?.name;
    },

    async checkCode() {
      this.loading = true;
      if (this.data.code == null)
        return (this.codeRequired = true), (this.loading = false);
      this.data.customer_id = this.invoice.customer.id;
      await this.checkCodeToVerifyCustomer(this.$formData(this.data))
        .then(() => {
          this.loading = false;
          this.catchError = false;
          this.isCorrectCode = true;
        })
        .catch((err) => {
          this.message = "Oups😢! Code incorrect";
          this.loading = false;
          this.catchError = true;
        });
    },

    async sendCode() {
      this.loading = true;
      await this.sendCodeToCustomer(this.$route.params.num)
        .then(() => {
          this.loading = false;
          this.isCodeSent = true;
        })
        .catch((err) => {
          this.loading = false;
          this.message = "Oups😢! Une erreur s'est produite";
          this.catchError = true;
        });
    },

    maskEmail(item) {
      let maskedEmail = item.customer?.email.replace(
        /^(.{2})(.*)(@.*)$/,
        "$1****$3"
      );
      return maskedEmail;
    },

    validateThisDevis(data) {
      this.isValidating = true;
      this.validateData.email = this.invoice.customer?.email;
      this.validateData.devis_num = data.devis_num_enterprise_id;
      this.devisValidate(this.validateData )
        .then((e) => {
          this.isValidate = true;
          this.isValidating = false;
        })
        .catch((err) => {
          this.isValidating = false;
          this.message = "Oups😢! Une erreur s'est produite";
        });
    },

    resetThisDevis(data) {
      this.devisReset(data.devis_num_enterprise_id).then((e) => {
        this.isReset = true;
      });
    },
    ...mapActions("tasks", [
      "getDevisCustomer",
      "sendCodeToCustomer",
      "devisValidate",
      "devisReset",
      "checkCodeToVerifyCustomer",
    ]),
  },
};
</script>